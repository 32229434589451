import type { useTheme } from "lib/theme";
import {
  HslTheme,
  InterfacesThemeSchema,
  type InterfacesTheme,
} from "lib/theme/schema";
import { defaultCustomizableThemeColors } from "lib/theme/themes/app-theme";
import { merge } from "lodash";
import {
  ReactNode,
  createContext,
  memo,
  useCallback,
  useContext,
  useDeferredValue,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import type { ProjectAppearance } from "server/schemas/projects";
import { ThemeProvider as SCThemeProvider } from "styled-components";
import {
  generateCSSCustomProperties,
  generateZiPalette,
  toTwHsl,
} from "./tailwind-palette-generator";
import getTheme from "./themes";
import { SCHEMES } from "./use-color-scheme";

export type SetInterfacesTheme = (
  theme: Partial<InterfacesTheme> | null
) => void;

const InterfacesThemeContext = createContext<
  InterfacesTheme | null | undefined
>(undefined);

const SetInterfacesThemeContext = createContext<SetInterfacesTheme | undefined>(
  undefined
);

export default function ThemeProvider({
  children,
  projectAppearance,
  themeName,
  ...props
}: {
  children: ReactNode;
  /**
   * The `null` | `undefined` types here is on purpose and they are REQUIRED on purpose.
   * We had quite a few bugs, were we forgot to pass the `projectAppearance` because it was optional.
   */
  projectAppearance: ProjectAppearance | undefined | null;
  interfacesTheme: Partial<InterfacesTheme> | undefined | null;

  themeName?: string;
}) {
  const rootQualifier = themeName ? `.${themeName}` : ":root";

  const darkQualifier = themeName
    ? `.dark.${themeName}, .dark .${themeName}`
    : ".dark";

  const { theme, resetTheme, setTheme } = useThemeState({ projectAppearance });
  const deferredTheme = useDeferredValue(theme);

  const cssProperties = useMemo<string>(() => {
    const palette = generateZiPalette(theme.app.colors);
    const cssProperties = generateCSSCustomProperties(palette);
    return cssProperties;
  }, [theme]);

  const [interfacesTheme, setInterfacesTheme] = useInitInterfacesTheme(
    props.interfacesTheme
  );

  const previewProviderValue = useMemo(() => {
    return {
      setTheme,
      resetTheme,
    };
  }, [resetTheme, setTheme]);

  return (
    <SetInterfacesThemeContext.Provider value={setInterfacesTheme}>
      <InterfacesThemeContext.Provider value={interfacesTheme}>
        <PreviewThemeContext.Provider value={previewProviderValue}>
          {/* The component you pass the deferred value to must be memoized.
      https://react.dev/reference/react/useDeferredValue#deferring-re-rendering-for-a-part-of-the-ui */}
          <MemoizedSCThemeProvider
            deferredTheme={deferredTheme}
            rootQualifier={rootQualifier}
            darkQualifier={darkQualifier}
            cssProperties={cssProperties}
            pageWidth={theme.app.pageWidth}
          >
            {children}
          </MemoizedSCThemeProvider>
        </PreviewThemeContext.Provider>
      </InterfacesThemeContext.Provider>
    </SetInterfacesThemeContext.Provider>
  );
}

const MemoizedSCThemeProvider = memo(function MemoizedSCThemeProvider({
  deferredTheme,
  rootQualifier,
  darkQualifier,
  cssProperties,
  pageWidth,
  children,
}: {
  deferredTheme: ReturnType<typeof getTheme>;
  rootQualifier: string;
  darkQualifier: string;
  cssProperties: string;
  pageWidth: number;
  children: ReactNode;
}) {
  const hslTheme = useHslTheme();
  return (
    <SCThemeProvider theme={deferredTheme}>
      <style global jsx>{`
        ${rootQualifier} {
          ${cssProperties}

          --zi-pageWidth: ${pageWidth}px;

          --radius: ${hslTheme?.radius};

          --background: ${hslTheme?.background};
          --foreground: ${hslTheme?.foreground};

          --card: ${hslTheme?.card};
          --card-foreground: ${hslTheme?.cardForeground};

          --popover: ${hslTheme?.popover};
          --popover-foreground: ${hslTheme?.popoverForeground};

          --primary: ${hslTheme?.primary};
          --primary-foreground: ${hslTheme?.primaryForeground};

          --secondary: ${hslTheme?.secondary};
          --secondary-foreground: ${hslTheme?.secondaryForeground};

          --muted: ${hslTheme?.muted};
          --muted-foreground: ${hslTheme?.mutedForeground};

          --accent: ${hslTheme?.accent};
          --accent-foreground: ${hslTheme?.accentForeground};

          --destructive: ${hslTheme?.destructive};
          --destructive-foreground: ${hslTheme?.destructiveForeground};

          --border: ${hslTheme?.border};
          --input: ${hslTheme?.input};
          --ring: ${hslTheme?.ring};
        }

        /* These are exactly the same as above... eventually we'll support dark mode. */
        ${darkQualifier} {
          ${cssProperties}

          --zi-pageWidth: ${pageWidth}px;

          --background: ${hslTheme?.darkBackground};
          --foreground: ${hslTheme?.darkForeground};

          --card: ${hslTheme?.darkCard};
          --card-foreground: ${hslTheme?.darkCardForeground};

          --popover: ${hslTheme?.darkPopover};
          --popover-foreground: ${hslTheme?.darkPopoverForeground};

          --primary: ${hslTheme?.darkPrimary};
          --primary-foreground: ${hslTheme?.darkPrimaryForeground};

          --secondary: ${hslTheme?.darkSecondary};
          --secondary-foreground: ${hslTheme?.darkSecondaryForeground};

          --muted: ${hslTheme?.darkMuted};
          --muted-foreground: ${hslTheme?.darkMutedForeground};

          --accent: ${hslTheme?.darkAccent};
          --accent-foreground: ${hslTheme?.darkAccentForeground};

          --destructive: ${hslTheme?.darkDestructive};
          --destructive-foreground: ${hslTheme?.darkDestructiveForeground};

          --border: ${hslTheme?.darkBorder};
          --input: ${hslTheme?.darkInput};
          --ring: ${hslTheme?.darkRing};
        }
      `}</style>
      {children}
    </SCThemeProvider>
  );
});

type PreviewThemeProps = {
  setTheme: (theme: ReturnType<typeof getTheme>) => void;
  resetTheme: VoidFunction;
};

const PreviewThemeContext = createContext<PreviewThemeProps | null>(null);

export function usePreviewTheme() {
  const providerValue = useContext(PreviewThemeContext);
  if (!providerValue) {
    throw Error("Preview theme provider value should be present");
  }

  return providerValue;
}

export function buildTheme({
  projectAppearance,
  currentTheme,
}: {
  projectAppearance: ProjectAppearance;
  currentTheme: ReturnType<typeof useTheme>;
}) {
  const userAppearanceSetting = {
    app: {
      colors: {
        ...defaultCustomizableThemeColors,
        ...projectAppearance,
        themeBackground:
          projectAppearance?.pageBackground ??
          currentTheme.app.colors.themeBackground,
        pageBackground:
          projectAppearance?.pageBackground ??
          currentTheme.app.colors.themeBackground,
      },
    },
  };

  /**
   * The `merge` will mutate the first object, so we need to pass an empty object as the first argument.
   */
  return merge({}, currentTheme, userAppearanceSetting);
}

const buildInitialTheme = ({
  projectAppearance = {},
  scheme,
}: {
  projectAppearance?: ProjectAppearance;
  scheme: SCHEMES;
}) => {
  const currentTheme = getTheme(scheme);
  return buildTheme({ projectAppearance, currentTheme });
};

const scheme: SCHEMES = SCHEMES.LIGHT;

function useThemeState({
  projectAppearance,
}: {
  projectAppearance: ProjectAppearance | undefined;
}) {
  const [theme, setTheme] = useState(() => {
    return buildInitialTheme({
      projectAppearance,
      scheme,
    });
  });

  /**
   * This ensures the `resetTheme` is stable, even if the `projectAppearance` changes.
   * We need it to be stable, to reset the theme when the "Customize colors" form unmounts.
   *
   * This could happen in two cases:
   * 1. The user clicks the "X" on the drawer.
   * 2. The user focuses a block within the main builder when the drawer is open.
   *
   * The second case does not trigger onClose event, so we need to reset the theme when the form unmounts.
   */
  const latestProjectAppearance = useRef(projectAppearance);
  useEffect(() => {
    latestProjectAppearance.current = projectAppearance;
  }, [projectAppearance]);

  const resetTheme = useCallback(() => {
    const theme = buildInitialTheme({
      projectAppearance: latestProjectAppearance.current,
      scheme,
    });

    setTheme(theme);
  }, []);

  return { theme, setTheme, resetTheme };
}

function useInitInterfacesTheme(theme?: Partial<InterfacesTheme> | null) {
  const [interfacesTheme, setInterfacesTheme] =
    useState<InterfacesTheme | null>(() => {
      if (!theme) {
        return null;
      }

      return InterfacesThemeSchema.parse(theme);
    });

  const applyInterfacesTheme = useCallback(
    (theme: Partial<InterfacesTheme> | null) => {
      const parsedTheme = theme ? InterfacesThemeSchema.parse(theme) : null;
      setInterfacesTheme(parsedTheme);
    },
    []
  );

  useEffect(() => {
    if (theme) {
      setInterfacesTheme(InterfacesThemeSchema.parse(theme));
    }
  }, [theme]);

  return [interfacesTheme, applyInterfacesTheme] as const;
}

export function useInterfacesTheme() {
  const theme = useContext(InterfacesThemeContext);
  if (theme === undefined) {
    throw new Error("useInterfacesTheme must be used within a ThemeProvider");
  }

  return theme;
}

function getHslTheme(interfacesTheme: Partial<InterfacesTheme>): HslTheme {
  const { radius, ...hexColors } = InterfacesThemeSchema.parse(interfacesTheme);
  return Object.entries(hexColors).reduce(
    (acc, [colorName, hexColor]) => {
      const hsl = toTwHsl(hexColor).split(" / ").shift();
      if (hsl) {
        acc[colorName as keyof HslTheme] = hsl;
      }
      return acc;
    },
    { radius } as HslTheme
  );
}

function useHslTheme() {
  const interfacesTheme = useInterfacesTheme();

  return useMemo(() => {
    return interfacesTheme ? getHslTheme(interfacesTheme) : null;
  }, [interfacesTheme]);
}

export function useSetInterfacesTheme() {
  const setInterfacesTheme = useContext(SetInterfacesThemeContext);
  if (setInterfacesTheme === undefined) {
    throw new Error(
      "useSetInterfacesTheme must be used within a ThemeProvider"
    );
  }

  return setInterfacesTheme;
}

export type { InterfacesTheme };
